import { max } from "moment";
import { borderRadius } from "react-select/src/theme";

// VEHICLE SEARCH SELECTORS
export const vehicleSearchTheme = (provided: any) => ({
  ...provided,
  borderRadius: 0,
});

export const getVehicleSearchStyle = (screen1440?: boolean) => {
  return {
    control: (provided: any) => ({
      ...provided,
      cursor: 'pointer',
      height: screen1440 ? 52 : 75,
      minHeight: screen1440 ? 52 : 75,
      fontSize: screen1440 ? 16 : 28,
      fontWeight: 400,
      background: "rgba(255, 255, 255, 0.8)",
      padding: "0 15px",
      border: "3px solid white",
      boxShadow: 'none',
      ":hover": {
        borderColor: "white",
        background: "rgba(255, 255, 255, 0.95)",
      }
    }),
    placeholder: (provided: any, {isDisabled}: any) => ({
      ...provided,
      color: '#1A1C1F',
      fontWeight: 400,
      opacity: isDisabled ? 0.5 : 1,
    }),
    indicatorSeparator: () => ({ display: "none", }),
    dropdownIndicator: (provided: any, {isDisabled}: any) => ({
      ...provided,
      color: '#1A1C1F',
      width: 34,
      padding: 0, 
      opacity: isDisabled ? 0.5 : 1,
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: 2,
      maxHeight: '30vh',
      zIndex: 5000
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: '30vh',
      zIndex: 5000
    }),
    option: (provided: any, { isFocused, isSelected }: any) => ({
      ...provided,
      border: 'none',
      cursor: 'pointer',
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 15px",
      fontSize: screen1440 ? 16 : 22,
      height: screen1440 ? 45 : 60,
      minHeight: screen1440 ? 45 : 60,
      color: '#1A1C1F',
      fontWeight: isSelected ? '500' : '400',
      backgroundColor: isSelected ? '#E6F2FF' : isFocused ? '#f5f5f5' : 'white'
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#1A1C1F',
    })
  };
}

export const getVehicleSearchStyleBoi = (screen1440?: boolean) => {
  return {
    control: (provided: any) => ({
      ...provided,
     cursor: 'pointer',
     height: screen1440 ? 52 : 75,
     minHeight: screen1440 ? 52 : 75,
     fontSize: screen1440 ? 16 : 28,
     fontWeight: 400,
     background: "rgba(255, 255, 255, 0.8)",
     padding: "0 15px",
     borderRadius: 8,
     boxShadow: 'none',
     ":hover": {
       borderColor: "#0000FF",
       background: "rgba(255, 255, 255, 0.95)",
     }
   }),
   placeholder: (provided: any, {isDisabled}: any) => ({
     ...provided,
     color: '#1A1C1F',
     fontWeight: 400,
     opacity: isDisabled ? 0.5 : 1,
   }),
   indicatorSeparator: () => ({ display: "none", }),
   dropdownIndicator: (provided: any, {isDisabled}: any) => ({
     ...provided,
     color: "#0000FF !important",
     width: 34,
     padding: 0,
     "& svg": {
       fill: "#0000FF",
     },
     opacity: isDisabled ? 0.5 : 1,
   }),
   menu: (provided: any) => ({
     ...provided,
     borderRadius: 2,
     maxHeight: '30vh',
     zIndex: 5000
   }),
   menuList: (provided: any) => ({
     ...provided,
     maxHeight: '30vh',
     zIndex: 5000
   }),
   option: (provided: any, { isFocused, isSelected }: any) => ({
     ...provided,
     border: 'none',
     cursor: 'pointer',
     display: "flex",
     alignItems: "center",
     justifyContent: "space-between",
     padding: "0 15px",
     fontSize: screen1440 ? 16 : 22,
     height: screen1440 ? 45 : 60,
     minHeight: screen1440 ? 45 : 60,
     color: '#1A1C1F',
     fontWeight: isSelected ? '500' : '400',
     backgroundColor: isSelected ? '#E6F2FF' : isFocused ? '#f5f5f5' : 'white'
   }),
   singleValue: (provided: any) => ({
     ...provided,
     color: '#1A1C1F',
   })
 };
}
// END OF VEHICLE SEARCH SELECTORS
// VEHICLE SELECTORS
export const vehicleTheme = (provided: any) => ({
  ...provided,
  borderRadius: 0,
});

export const getVehicleStyle = (smallSelector?: boolean, evModal?: boolean, rounded?: boolean) => {
  return {
    control: (provided: any) => ({
     ...provided,
     cursor: 'pointer',
     height: 50,
      minHeight: 50,
      fontWeight: 400,
      padding: smallSelector ? "0 10px 0 18px" : "0 18px",
      border: "1px solid #DEDEDE",
      boxShadow: 'none',
      borderRadius: rounded ? 8 : 0,

      ":hover": {
        borderColor: "#0FE0D1",
      },
      ":focus": {
        borderColor: "#0FE0D1",
      }
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#1A1C1F',
      fontWeight: 400
    }),
    input: (provided: any) => ({
      ...provided,
      color: '#1A1C1F',
      fontWeight: 400,
    }),
    indicatorSeparator: () => ({ display: "none", }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: '#1A1C1F',
      width: 18,
      padding: 0, 
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: 2,
      maxHeight: evModal ? '20vh' : '25vh',
      zIndex: 5000
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: evModal ? '20vh' : '25vh',
      zIndex: 5000
    }),
    option: (provided: any, { isFocused, isSelected }: any) => ({
      ...provided,
      border: 'none',
      cursor: 'pointer',
      display: "flex",
      justifyContent: "space-between",
      padding: "12px 15px",
      fontSize: 16,
      minHeight: 42,
      height: 'fit-content',
      color: '#1A1C1F',
      overflow: 'hidden',
      fontWeight: 400,
      backgroundColor: isSelected ? '#80F0E4' : isFocused ? '#E6F2FF' : 'white', // Lighter background for selected
      ":hover": {
        backgroundColor: "#0FE0D1", // Hover effect for option
      }
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#1A1C1F',
    })
  };
}

export const getVehicleStyleBoi = (smallSelector?: boolean, evModal?: boolean, rounded?: boolean) => {
  return {
    control: (provided: any) => ({
      ...provided,
      cursor: 'pointer',
      height: 50,
      minHeight: 50,
      fontWeight: 400,
      padding: smallSelector ? "0 10px 0 18px" : "0 18px",
      borderRadius: 8,
      border: "1px solid #DEDEDE",
      boxShadow: 'none',
      ":hover": {
        borderColor: "#0000FF",
        background: "rgba(255, 255, 255, 0.95)",
      }
    }),
    placeholder: (provided: any) => ({
      ...provided,
     color: '#1A1C1F',
     fontWeight: 400
   }),
   input: (provided: any) => ({
     ...provided,
     color: '#1A1C1F',
     fontWeight: 400,
   }),
   indicatorSeparator: () => ({ display: "none", }),
   dropdownIndicator: (provided: any) => ({
     ...provided,
     color: "#0000FF !important",
     width: 18,
     padding: 0,
     "& svg": {
       fill: "#0000FF",
     }
   }),
   menu: (provided: any) => ({
     ...provided,
     borderRadius: 2,
     maxHeight: evModal ? '20vh' : '25vh',
     zIndex: 5000
   }),
   menuList: (provided: any) => ({
     ...provided,
     maxHeight: evModal ? '20vh' : '25vh',
     zIndex: 5000
   }),
   option: (provided: any, { isFocused, isSelected }: any) => ({
     ...provided,
     border: 'none',
     cursor: 'pointer',
     display: "flex",
     justifyContent: "space-between",
     padding: "12px 15px",
     fontSize: 16,
     minHeight: 42,
     height: 'fit-content',
     color: '#1A1C1F',
     overflow: 'hidden',
     fontWeight: 400,
     backgroundColor: isSelected ? '#E6F2FF' : isFocused ? '#f5f5f5' : 'white'
   }),
   singleValue: (provided: any) => ({
     ...provided,
     color: '#1A1C1F',
   })
 };
}
// END OF VEHICLE SELECTORS
// FOR HOTEL SELECTORS
export const hotelSelectorTheme = (provided: any) => ({
  ...provided,
  borderRadius: 0,
});

export const hotelSelectorStyle = {
  control: (provided: any) => ({
    ...provided,
    height: 50,
    minHeight: 50,
    fontWeight: 400,
    fontSize: 18,
    background: "#F3F4F580",
    padding: "0 20px",
    border: "2px solid #F3F4F5",
    cursor: "pointer",

    ":hover": {
        borderColor: "#0FE0D1",
      },
      ":focus": {
        borderColor: "#0FE0D1",
      }
  }),
  menu: (provided: any) => ({
    ...provided,
    maxHeight: 150,
    zIndex: 5
  }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: 150,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#8D949D',
  }),
  input: (provided: any) => ({
    ...provided,
    color: '#1A1C1F',
  }),
  indicatorSeparator: () => ({ display: "none" }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    width: 20,
    padding: 0,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#1A1C1F',
  })
};

export const hotelSelectorStyleBoi = {
  control: (provided: any) => ({
    ...provided,
    height: 50,
   minHeight: 50,
   fontWeight: 400,
   fontSize: 18,
   background: "#F3F4F580",
   padding: "0 20px",
   border: "1px solid #666666",
   borderRadius: 8,
   cursor: "pointer",
 }),
 menu: (provided: any) => ({
   ...provided,
   maxHeight: 150,
   zIndex: 5
 }),
 menuList: (provided: any) => ({
   ...provided,
   maxHeight: 150,
 }),
 placeholder: (provided: any) => ({
   ...provided,
   color: '#8D949D',
 }),
 input: (provided: any) => ({
   ...provided,
   color: '#1A1C1F',
 }),
 indicatorSeparator: () => ({ display: "none" }),
 dropdownIndicator: (provided: any) => ({
   ...provided,
   color: "#0000FF !important",
   width: 20,
   padding: 0,
   "& svg": {
       fill: "#0000FF",
     }
 }),
 valueContainer: (provided: any) => ({
   ...provided,
   padding: 0,
 }),
 singleValue: (provided: any) => ({
   ...provided,
   color: '#1A1C1F',
 })
};
// OPTION SELECTORS
export const optionTheme = (provided: any) => ({
  ...provided,
  borderRadius: 0,
});

export const getOptionStyle = (mobile: boolean) => {
  return {
    control: (provided: any, { isFocused } : any) => ({
      ...provided,
      cursor: 'pointer',
      height: mobile ? 49.5 : 59.5,
      minHeight: mobile ? 47 : 50,
      fontSize: mobile ? 14 : 16,
      fontWeight: 400,
      padding: mobile ? "0 5px" : "0 10px",
      border: isFocused ? "1px solid #1A1C1F !important" : "1px solid rgba(141, 148, 157, 0.5)",
      outline: "none",
      boxShadow: 'none',
      "&:hover": {
        borderColor: "transparent"
      }
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#8d949d",
      fontWeight: 300,
      fontSize: '18px'
    }),
    input: (provided: any) => ({
      ...provided,
      color: '#1A1C1F',
      fontWeight: 300,
      fontSize: '18px'
    }),
    indicatorSeparator: () => ({ display: "none", }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: '#1A1C1F',
      width: 18,
      padding: 0, 
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: 2,
      maxHeight: '175px',
      zIndex: 5000
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: '175px',
      zIndex: 5000
    }),
    option: (provided: any, { isFocused, isSelected }: any) => ({
      ...provided,
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      padding: "0 15px",
      fontSize: mobile ? 14 : 16,
      height: 42,
      minHeight: 42,
      color: '#1A1C1F',
      fontWeight: 400,
      backgroundColor: isSelected ? '#E6F2FF' : isFocused ? '#f5f5f5' : 'white'
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#1A1C1F',
      fontSize: "18px",
      fontWeight: 300
    })
  };
}

export const getOptionStyleBoi = (mobile: boolean) => {
  return {
    control: (provided: any, { isFocused } : any) => ({
      ...provided,
     cursor: 'pointer',
     height: mobile ? 49.5 : 59.5,
     minHeight: mobile ? 47 : 50,
     fontSize: mobile ? 14 : 16,
     fontWeight: 400,
     padding: mobile ? "0 5px" : "0 10px",
     border: isFocused ? "1px solid #1A1C1F !important" : "1px solid rgba(141, 148, 157, 0.5)",
     outline: "none",
     boxShadow: 'none',
     "&:hover": {
       borderColor: "transparent"
     }
   }),
   placeholder: (provided: any) => ({
     ...provided,
     color: "#8d949d",
     fontWeight: 300,
     fontSize: '18px'
   }),
   input: (provided: any) => ({
     ...provided,
     color: '#1A1C1F',
     fontWeight: 300,
     fontSize: '18px'
   }),
   indicatorSeparator: () => ({ display: "none", }),
   dropdownIndicator: (provided: any) => ({
     ...provided,
     color: "#0000FF !important",
     width: 18,
     padding: 0,
     "& svg": {
       fill: "#0000FF",
     }
   }),
   menu: (provided: any) => ({
     ...provided,
     borderRadius: 2,
     maxHeight: '175px',
     zIndex: 5000
   }),
   menuList: (provided: any) => ({
     ...provided,
     maxHeight: '175px',
     zIndex: 5000
   }),
   option: (provided: any, { isFocused, isSelected }: any) => ({
     ...provided,
     border: 'none',
     cursor: 'pointer',
     display: 'flex',
     alignItems: 'center',
     padding: "0 15px",
     fontSize: mobile ? 14 : 16,
     height: 42,
     minHeight: 42,
     color: '#1A1C1F',
     fontWeight: 400,
     backgroundColor: isSelected ? '#E6F2FF' : isFocused ? '#f5f5f5' : 'white'
   }),
   singleValue: (provided: any) => ({
     ...provided,
     color: '#1A1C1F',
     fontSize: "18px",
     fontWeight: 300
   })
 };
}
// END OF OPTION SELECTORS